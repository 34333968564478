<template>
  <div>
    <nav class="navbar">
      <div class="w-100 d-inline-flex align-items-center">
        <img class="cursor-pointer btn-back" @click="back()" v-if="backPage" src="@/assets/images/icons/ic_back.svg" alt="">
        <p class="mb-0 w--100 text-center" v-if="!fromApp">{{ $store.getters.loginInfo.shop_name }}</p>
        <b-dropdown
          v-else
          :text="$store.getters.loginInfo ? $store.getters.loginInfo.shop_name : $t('login.choose_shop')"
          class="w-100 mx-2"
          v-click-out="clickOutDropdown"
          block
        >
          <b-dropdown-item
            v-for="(shop, i) in $store.getters.locations"
            :key="i"
            :active="$store.getters.loginInfo && shop.shop_id === $store.getters.loginInfo.shop_id"
            @click="getShop(shop)">
            {{ shop.shop_name }}
          </b-dropdown-item>
        </b-dropdown>

        <b-button class="p-0" v-b-toggle.sidebar-right>
          <img src="@/assets/images/icons/ic_menu.svg" alt="" height="16">
        </b-button>
      </div>
    </nav>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {ACTION_LOGIN} from "@/store/auth/actions";

export default {
  name: "Navbar",
  computed: {
    ...mapGetters(['backPage']),
    fromApp: function() {
      return navigator.userAgent === 'FromApp'
    }
  },
  methods: {
    clickOutDropdown() {
      if (document.getElementsByClassName('dropdown-menu') && document.getElementsByClassName('dropdown-menu')[0]) {
        document.getElementsByClassName('dropdown-menu')[0].classList.remove('show')
      }
      // document.getElementsByClassName('flexible-height')[0].classList.remove('fix-height-162')
    },
    getShop(shop) {
      if(shop.shop_name != this.$store.getters.loginInfo.shop_name) {
        this.$toast.success('店舗が切り替わりました')
      }
      this.$store.dispatch(ACTION_LOGIN, shop)
    },
    goTo(link) {
      this.$router.push({name: link}, () => {})
    },
    back() {
      this.$router.push({name: this.backPage}, () => {})
    }
  }
}
</script>

<style lang="scss">
.navbar {
  width: 100%;
  top: 0;
  color: #ffffff;
  padding: 1.3rem 1rem;
  .btn-secondary {
    background-color: transparent !important;
    border-color: transparent !important;
    color: #2f2f2f !important;
  }
  .btn-secondary:focus {
    box-shadow: none;
  }
  div {
    justify-content: space-between;
  }
  .btn-back {
    height: 1rem;
  }
  

  .dropdown {
    button {
      background-color: $white !important;
      color: $dark !important;
      border-radius: 10px;
      &::after {
        position: absolute;
        right: 8px;
        top: 18px;
      }
    }

    ul {
      width: 100%;
      padding: 0;
      max-height: 122px;
      overflow-y: auto;
      border: none;
      li {
        &:last-child{
          .dropdown-item {
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
          }
        }
      }
      .dropdown-item {
        min-height: 40px;
        align-items: center;
        display: flex;
        background-color: #F2D6DE;
        border-top: 1px solid $white;

        &.active {
          background-color: #E95074;
        }
      }
    }
  }
}
</style>
