<template>
  <div class="fix-width">
    <div class="bb">
      <navbar></navbar>
      <sidebar></sidebar>
      <router-view></router-view>
      <footbar></footbar>
    </div>
    <loading v-if="isLoading"></loading>
  </div>
</template>

<script>
    import Sidebar from "@/components/layout/Sidebar";
    import Navbar from "@/components/layout/Navbar";
    import Footbar from "@/components/layout/Footbar";
    import store from "@/store";
    import {ACTION_LOGOUT} from "@/store/auth/actions";

    export default {
      name: "Auth",
      data() {
        return {
          currentTimestamp: (new Date()).getTime()
        }
      },
      computed: {
        isLoading() {
          return this.$store.getters.loadingApi
        }
      },
      watch: {
        isLoading() {
          if (this.isLoading) {
            document.body.setAttribute('scroll', 'no')
            document.body.style.overflow = 'hidden'
          } else {
            document.body.removeAttribute('scroll')
            document.body.removeAttribute('style')
          }
        },
        currentTimestamp() {
          let loginInfo = this.$store.getters.loginInfo || JSON.parse(localStorage.getItem('login_info'))
          let userAgent = navigator.userAgent
          if (userAgent != 'FromApp') {
            if (!(this.currentTimestamp < loginInfo.expired)) {
              store.dispatch(ACTION_LOGOUT).then(() => {
                this.$router.push({name: 'login'}, () => {
                  this.$toast.warning('ログインセッションの有効期限が切れています。もう一度ログインしてください')
                })
              })
            }
          }
        },
      },
      created() {
        setInterval(() => {
          this.currentTimestamp = (new Date()).getTime()
        }, 1000)
      },
      components: {
        Navbar,
        Sidebar,
        Footbar
      }
    }
</script>

<style lang="scss">
    @import "../../assets/css/main.css";
    #app {
      background: #E6E6E6;
      //min-height: 100vh;
    }
    .bv-example-row {
      margin-top: 75px;
      margin-bottom: 75px;
    }
    .fix-width {
      max-width: 100vw;
      margin: 0 auto;
    }
    .bb {
      background-image: url('~@/assets/images/header_color.svg');
      background-repeat: no-repeat;
      background-size: 100% auto;
      //background-attachment: fixed;
      background-position: center 0;
    }
    @media only screen and (min-width: 425px) {
      .bb {
        background-position: center -11vw;
      }
    }
    @media only screen and (min-width: 768px) {
      .bb {
        background-position: center -15vw;
      }
    }
    @media only screen and (min-width: 1024px) {
      .bb {
        background-position: center -20vw;
      }
    }
</style>
