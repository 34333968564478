<template>
  <div class="footer text-center">
    <p class="mb-0">Copyright © 2021 Ultra inc</p>
    <p class="mb-0">All Right Reserved</p>
  </div>
</template>

<script>
export default {
  name: "Footbar"
}
</script>

<style lang="scss" scoped>
.footer {
  font-size: 0.75rem;
  line-height: 1.125rem;
  width: 100%;
  color: white;
  background-color: #B3B3B3;
  padding-top: 2.1875rem;
  padding-bottom: 2.1875rem;
}
</style>
