<template>
  <div>
    <b-sidebar id="sidebar-right" right backdrop no-header shadow @shown="showSidebar" @hidden="hiddenSidebar">
      <template #default="{ hide }">
        <header class="b-sidebar-header">
          <button type="button" @click="hide" class="close text-dark">
            <img src="@/assets/images/icons/ic_exit.svg" alt="" width="16">
          </button>
        </header>
        <div class="pl-1 pr-1 mb-4">
          <img src="@/assets/images/logo.svg" alt="">
        </div>
        <div class="sidebar-item">
          <img src="@/assets/images/icons/ic_user.svg" alt="" width="20">
          {{ $t('sidebar.staffName') }}
          <br>
          <span class="staff-name">{{ locationSelected.staff_name }}</span>
        </div>
        <div class="sidebar-item" @click="goTo('home')">
          <img src="@/assets/images/icons/ic_home.svg" alt="" width="20">
          {{ $t('sidebar.home') }}
        </div>
        <div class="sidebar-item" @click="goTo('search')">
          <img src="@/assets/images/icons/ic_search.svg" alt="" width="20">
          {{ $t('sidebar.reservation_search') }}
        </div>
        <div class="sidebar-item" @click="logout()">
          <img src="@/assets/images/icons/ic_logout.svg" alt="" width="20">
          {{ $t('sidebar.logout') }}
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {ACTION_LOGOUT} from "@/store/auth/actions";

export default {
  name: "Sidebar",
  methods: {
    goTo(link) {
      this.$router.push({name: link}).catch(() => {
        this.$root.$emit('bv::toggle::collapse', 'sidebar-right')
      })
    },
    logout() {
      // todo map API
      this.$store.dispatch(ACTION_LOGOUT).then(() => {
        this.$router.push({name: 'login'}, () => {
          // this.$toast.success('Logout success')
        })
      }).catch(() => {
        // this.$toast.error('Logout failed')
      })
    },
    showSidebar() {
      document.body.setAttribute('scroll', 'no')
      document.body.style.overflow = 'hidden'
    },
    hiddenSidebar() {
      document.body.removeAttribute('scroll')
      document.body.removeAttribute('style')
    }
  }
}
</script>

<style lang="scss">
.b-sidebar-body {
  padding-left: 1rem;
  padding-right: 1rem;
}
.b-sidebar {
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
  width: 240px !important;
  background-color: #404040 !important;
  .b-sidebar-header {
    padding: 1rem 0;
    margin-bottom: 1rem;
    justify-content: flex-end;
    .close {
      color: #ffffff !important;
      margin-right: 0!important;
    }
  }
  .sidebar-item {
    border-top: 1px solid #656565;
    color: #c1c1c1;
    text-align: left;
    font-size: 1rem;
    line-height: 20px;
    padding: 1rem 0;
    img {
      margin-right: 0.7rem;
    }
    .staff-name {
      margin-left: calc(0.7rem + 20px);
      font-weight: 900;
    }
  }
  .sidebar-item:last-child {
    border-bottom: 1px solid #656565;
  }
}
</style>
